import React from "react";
import { brand1, brand2, brand3, brand4 } from "./imports.js";
import "./Brands.css";

function Brands({ bgcolor }) {
  return (
    <div className={bgcolor}>
      <div>
        <img src={brand2} alt="" style={{objectFit: 'contain'}}/>
      </div>
      <div>
        <img src={brand3} alt="" style={{objectFit: 'contain'}}/>
      </div>
    </div>
  );
}

export default Brands;
