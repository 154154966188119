import React from "react";
import "./Service.css";
import { Footer } from "../../containers/index";
import { Button } from "primereact/button";
import cardimg from "../../photo/gonestead.jpeg";
import card1 from "../../photo/card-img1.jpeg";
import pain1 from "../../photo/pains/pain1.jpeg";
import pain2 from "../../photo/pains/pain2.jpeg";
import pain3 from "../../photo/pains/pain3.jpeg";
import pain4 from "../../photo/pains/pain4.jpeg";
import { Navbar } from "../../components/index";
import NewHeader from "../../components/header/NewHeader.js";

function Service() {
    const Scard = ({ heading, img, context }) => {
        return (
            <div className='service-card'>
                <h2>{heading}</h2>
                <div className='liner'></div>
                <img src={img} alt='' />
                <p>{context}</p>
                <button>Learn more</button>
            </div>
        );
    };

    return (
        <div className='service'>
            <NewHeader />
            <Navbar />
            <div className='tile'>
                <h1>Service</h1>
                <div className='liner'></div>
            </div>

            <div className='service-main'>
                <div className='service-Cards'>
                    <Scard
                        heading={"Back Pain"}
                        img={card1}
                        context='Serious back pain can be better with Focus gonstead chiropractic skill we have best solution for that'
                    />

                    <Scard
                        heading={"Headache"}
                        img={pain1}
                        context={
                            "They can effect the patients’ ability to concentrate and be productive. The cause is often found in the up"
                        }
                    />
                    <Scard
                        heading={"Pelvic misalignment"}
                        img={pain4}
                        context={
                            "Pelvic missalighment occers when the pelvis tilts or twists. it is a very common complaint which result in several pains"
                        }
                    />
                    <Scard
                        heading={"Neck stiffness"}
                        img={pain2}
                        context={
                            "Neck stiffness may include a feeling of soreness, but presents mostly with difficulty moving your head.…"
                        }
                    />
                    <Scard
                        heading={"Rib misalignment"}
                        img={pain3}
                        context={
                            "A rib misalignment occurs when your rib moves slightly (backwards and inwards) from the thoracic vertebra towards the spine. The muscles in your back and between the ribs will then contract and tighten to prote…"
                        }
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Service;
