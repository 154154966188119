import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./containers";
import {
    FirstVisit,
    AboutUs,
    Service,
    Contacts,
    Gonstead,
} from "./pages/index";
import HashLoader from "react-spinners/HashLoader";
import AOS from "aos";

function App() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        AOS.init();
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }, []);
    return (
        <>
            <Router>
                {loading ? (
                    <div className='App'>
                        <HashLoader
                            color={"#707070"}
                            loading={loading}
                            size={60}
                        />
                    </div>
                ) : (
                    <>
                        <div className='main'>
                            <Routes>
                                <Route
                                    path='/'
                                    exact
                                    element={<Home />}
                                ></Route>
                                <Route
                                    path='/aboutus'
                                    element={<AboutUs />}
                                ></Route>
                                <Route
                                    path='/gonstead'
                                    element={<Gonstead />}
                                ></Route>
                                <Route
                                    path='/firstvisit'
                                    element={<FirstVisit />}
                                ></Route>
                                <Route
                                    path='/service'
                                    element={<Service />}
                                ></Route>
                                <Route
                                    path='/contacts'
                                    element={<Contacts />}
                                ></Route>
                            </Routes>
                        </div>
                    </>
                )}
            </Router>
        </>
    );
}

export default App;
