import React, { Component } from "react";
import Select from "react-select";
import "./FirstVisit.css";
import { Form } from "../../components/index";
import { Footer } from "../../containers/index";
import { Navbar } from "../../components/index";
import NewHeader from "../../components/header/NewHeader.js";

function FirstVisit() {
    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];

    return (
        <div className='firstvisit'>
            <NewHeader />
            <Navbar />
            <div className='tile'>
                <h1>First Visit</h1>
                <div className='liner'></div>
            </div>
            <div className='first-visit__box'>
                <div className='first-visit__box__left'>
                    <div>
                        <h1>Your First Visit</h1>
                        <h2>Fill Out New Patient Paperwork</h2>
                        <p>
                            Once we’ve scheduled your appointment, help us
                            prepare for your visit by filling out our Patient
                            Paperwork.
                        </p>
                        <h2>
                            Fill them out <a href=''>HERE</a>
                        </h2>
                        <h3>WHAT TO EXPECT?</h3>
                        <div className='liner-left'></div>
                        <div className='service-box'>
                            <div className='service'>
                                <h4>One on One Consultation</h4>
                                <p>
                                    We’ll take time to talk with you about your
                                    pain, what kinds of things you’ve tried to
                                    solve your problem and your specific health
                                    goals for the future.
                                </p>
                            </div>
                            <div className='service'>
                                <h4>Exam & Evalauation</h4>
                                <p>
                                    Our doctors use several methods to evaluate
                                    your posture, your structure, and your pain
                                    points to create an in-depth picture of your
                                    health. X-rays may be taken if necessary.
                                </p>
                            </div>
                            <div className='service'>
                                <h4>A Personalized Plan</h4>
                                <p>
                                    We’ll take time to talk with you about your
                                    pain, what kinds of things you’ve tried to
                                    solve your problem and your specific health
                                    goals for the future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='first-visit__box__right'>
                    <div className='fv-form'>
                        <div className='fv-form__heading'>
                            <h2>
                                Don't want to put up with the pain any longer?
                            </h2>
                            <div className='liner'></div>
                        </div>

                        <Form />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default FirstVisit;
