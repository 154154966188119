import React, { useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const MessagesDemo = () => {
  const msgs1 = useRef(null);
  const msgs2 = useRef(null);
  const msgs3 = useRef(null);

  useEffect(() => {
    msgs1.current.show([
      {
        severity: "success",
        summary: "Success",
        detail: "Message Submitted!",
        sticky: true,
      },
    ]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addMessages = () => {};

  const clearMessages = () => {
    msgs2.current.clear();
  };

  return (
    <div>
      <div className="card">
        <Messages ref={msgs1} />
      </div>
    </div>
  );
};
export default MessagesDemo;
