import React, { useState, useEffect, useRef } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { CountryService } from "./CountryService";
import "./Form.css";
import MessagesDemo from "./MessagesDemo.js";
import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";
init("n1ql_GW8MGqnFmPsw");

export const AutoCompleteDemo = () => {
  const form = useRef();

  const [countries, setCountries] = useState([]);
  const [selectedCountry1, setSelectedCountry1] = useState(null);
  const [selectedCountry2, setSelectedCountry2] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredCountries, setFilteredCountries] = useState(null);
  const [filteredCities, setFilteredCities] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);
  const countryservice = new CountryService();
  const [clicked, setClicked] = useState(false);

  const groupedCities = [
    {
      label: "Germany",
      code: "DE",
      items: [
        { label: "Berlin", value: "Berlin" },
        { label: "Frankfurt", value: "Frankfurt" },
        { label: "Hamburg", value: "Hamburg" },
        { label: "Munich", value: "Munich" },
      ],
    },
    {
      label: "USA",
      code: "US",
      items: [
        { label: "Chicago", value: "Chicago" },
        { label: "Los Angeles", value: "Los Angeles" },
        { label: "New York", value: "New York" },
        { label: "San Francisco", value: "San Francisco" },
      ],
    },
    {
      label: "Japan",
      code: "JP",
      items: [
        { label: "Kyoto", value: "Kyoto" },
        { label: "Osaka", value: "Osaka" },
        { label: "Tokyo", value: "Tokyo" },
        { label: "Yokohama", value: "Yokohama" },
      ],
    },
  ];

  const Data = [
    "Back Pain",
    "Muscle pain",
    "Frequent headache",
    "Neck stiffness",
    "healing from an accident",
    "Pelvic misalignment",
    "Rib misalignment",
    "Limited range of motion",
    "Chronic pain in your joints or muscles",
  ];

  const items = Data.map((i) => ({
    label: `${i}`,
    value: i,
  }));

  useEffect(() => {
    countryservice.getCountries().then((data) => setCountries(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchCountry = (event) => {
    setTimeout(() => {
      let _filteredCountries;
      if (!event.query.trim().length) {
        _filteredCountries = [...countries];
      } else {
        _filteredCountries = countries.filter((country) => {
          return country.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredCountries(_filteredCountries);
    }, 250);
  };

  const searchItems = (event) => {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let query = event.query;
    let _filteredItems = [];

    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }

    setFilteredItems(_filteredItems);
  };

  const groupedItemTemplate = (item) => {
    return (
      <div className="flex align-items-center country-item">
        <img
          alt={item.name}
          src={`images/flag/flag_placeholder.png`}
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${item.code.toLowerCase()}`}
        />
        <div>{item.label}</div>
      </div>
    );
  };

  const submittedForm = () => {
    console.log("submitted");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zmborna",
        "template_kharw7b",
        form.current,
        "D2S2xfX6jIYN61qpq"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <form className="card" ref={form} onSubmit={sendEmail}>
        <AutoComplete
          className="card-form"
          value={selectedCountry1}
          suggestions={filteredCountries}
          completeMethod={searchCountry}
          field="name"
          onChange={(e) => setSelectedCountry1(e.value)}
          placeholder="Your Name"
          name="name"
        />
        <AutoComplete
          className="card-form"
          value={selectedCity}
          suggestions={filteredCities}
          field="phone"
          optionGroupLabel="label"
          optionGroupChildren="items"
          optionGroupTemplate={groupedItemTemplate}
          onChange={(e) => setSelectedCity(e.value)}
          placeholder="Your Phone"
          name="phone"
        />
        <AutoComplete
          className="card-form"
          value={selectedCountry2}
          field="email"
          onChange={(e) => setSelectedCountry2(e.value)}
          placeholder="email"
          name="email"
        />

        <AutoComplete
          className="card-form"
          value={selectedItem}
          suggestions={filteredItems}
          completeMethod={searchItems}
          virtualScrollerOptions={{ itemSize: 38 }}
          field="label"
          dropdown
          onChange={(e) => setSelectedItem(e.value)}
          placeholder="Reason For Visit"
          name="reason"
        />

        <AutoComplete
          className="card-form"
          value={selectedCountries}
          suggestions={filteredCountries}
          completeMethod={searchCountry}
          field="name"
          multiple
          onChange={(e) => setSelectedCountries(e.value)}
          placeholder="Your Comment"
          name="comment"
        />

        <div className="card-form">
          <button className="contact-box__button">Submit</button>
        </div>
      </form>
    </>
  );
};
export default AutoCompleteDemo;
