import React from "react";
import "./Info4.css";
import possibilityImage from "../../photo/adj2.png";
import possibilityImage2 from "../../photo/card-image2.jpeg";
import possibilityImage3 from "../../photo/example-patients.jpeg";

function Info4() {
  return (
    <section className="info4-section">
      <div className="gpt3__possibility section__padding" id="possibility">
        <div className="gpt3__possibility-image">
          <img src={possibilityImage} alt="possibility" />
        </div>
        <div className="gpt3__possibility-content">
          <h4>Request Early Access to Get Started</h4>
          <h1 className="gradient__text">
            Backpain <br /> treatment
          </h1>
          <p>
            When patients with non-specific chronic low back pain are treated by
            chiropractors, the long-term outcome is enhanced by obtaining
            maintenance spinal manipulation after the initial intensive
            manipulative therapy.
          </p>
          <h4>Request Early Access to Get Started</h4>
        </div>
      </div>
      <div className="gpt3__possibility section__padding" id="possibility">
        <div className="gpt3__possibility-content">
          <h4>Request Early Access to Get Started</h4>
          <h1 className="gradient__text">
            Stress <br /> Controls
          </h1>
          <p>
            chiropractors focus on the underlying cause of headache and neck
            pain which can be either in the soft tissues or neurologic in
            nature. The treatments address the root cause of headache rather
            than just masking the short-term symptoms.
          </p>
          <h4>Request Early Access to Get Started</h4>
        </div>
        <div className="gpt3__possibility-image">
          <img src={possibilityImage2} alt="possibility" />
        </div>
      </div>
      <div className="gpt3__possibility section__padding" id="possibility">
        <div className="gpt3__possibility-image">
          <img src={possibilityImage3} alt="possibility" />
        </div>
        <div className="gpt3__possibility-content">
          <h4>Request Early Access to Get Started</h4>
          <h1 className="gradient__text">
            X-ray <br /> consultant
          </h1>
          <p>
            X-rays are standard procedures. In most cases, you won’t need to
            take special steps to prepare for them. Depending on the area that
            your doctor and radiologist are examining, you may want to wear
            loose, comfortable clothing that you can easily move around in. They
            may ask you to change into a hospital gown for the test. They may
            also ask you to remove any jewelry or other metallic items from your
            body before your X-ray is taken.
          </p>
          <h4>Request Early Access to Get Started</h4>
        </div>
      </div>
    </section>
  );
}

export default Info4;
