import React from "react";
import "./Info.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import { Button } from "primereact/button";

function Info() {
  return (
    <section className="section">
      <div className="info-section">
        <div className="info-container reveal fade-bottom active">
          <h1>Why Customers Choose Us?</h1>

          <h2>
            Chiropractors focus on disorders of the musculoskeletal system and
            the nervous system, and the effects of these disorders on general
            health. Chiropractic care is used most often to treat problems
            including (but not limited to) back pain, neck pain, pain in the
            joints of the arms or legs, and headaches.
          </h2>
          <h3>
            Chiropractors practice a hands-on, drug-free approach to health care
            that includes patient examination, diagnosis, and treatment.
            Chiropractors have broad diagnostic skills and are perhaps best
            known for their expertise in the use of spinal manipulation;
            however, they are also trained to recommend therapeutic and
            rehabilitative exercises, and to provide nutritional, dietary and
            lifestyle counseling.
          </h3>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1644381578">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
    </section>
  );
}

export default Info;
