import React from "react";
import "./Aboutus.css";
import { BigCard } from "../../components/index";
import chiro1 from "../../photo/chiro1.jpeg";
import logo2 from "../../photo/noback.png";
import { AiOutlineCheck } from "react-icons/ai";
import { Footer } from "../../containers/index";
import { Navbar } from "../../components/index";
import NewHeader from "../../components/header/NewHeader.js";

function Aboutus() {
    return (
        <div className='about-us'>
            <NewHeader />
            <Navbar />
            <div className='tile'>
                <h1>About Us</h1>
                <div className='liner'></div>
            </div>
            <BigCard />
            <div className='bigcard-box1'>
                <div className='bigcard-box__left1'>
                    <img src={logo2} alt='' />
                </div>
                <div className='bigcard-box__right1'>
                    <div className='mol'>
                        <div>
                            <h1>CHIROPRACTIC FOR HEALTHY MOVEMENT</h1>
                            <h2>
                                We treat many neurological and musculoskeletal
                                issues and focus on recovery of active and
                                sports related injuries.
                            </h2>
                            <h2>So, if you are dealing with:</h2>
                            <div className='treats'>
                                <div className='treat'>
                                    <ul>
                                        <h3>Spinal Related</h3>
                                        <li>Back pain</li>

                                        <li>Neck pain</li>
                                        <li>Headache/Migraine</li>
                                        <li>Sciatica</li>
                                        <li>Scoliosis</li>
                                        <li>Neurological</li>

                                        <li>Eftpos & Hi-Caps availble</li>
                                    </ul>
                                </div>
                                <div className='treat'>
                                    <ul>
                                        <h3>Family Care</h3>
                                        <li>Pregnancy</li>

                                        <li>Chiropractic Care for Kids</li>
                                        <li>Wellness</li>
                                    </ul>
                                </div>
                                <div className='treat'>
                                    <ul>
                                        <h3>Injuries</h3>

                                        <li>Whiplash</li>
                                        <li>Disc Injury</li>
                                        <li>Sports Injury</li>
                                        <li>
                                            Extremity- CTS, Shoulder,
                                            <br /> Wrist, Elbow Knee, <br />
                                            Ankle Hands/Feet
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bigcard-box'>
                <div className='bigcard-box__left2'>
                    <div className='mol2'>
                        <div>
                            <h1>Our Family Care Philosophy</h1>
                        </div>
                        <div>
                            <h4>
                                Having great health starts early in life. We
                                emphasize the importance of chiropractic care
                                for babies, children and expecting moms. It’s
                                our goal to make sure that your kids grow up
                                avoiding common problems. We’ve gotten
                                incredible results for our pediatric patients.
                                We welcome your entire family to experience what
                                we can do for them!
                            </h4>
                        </div>
                    </div>
                </div>
                <div className='bigcard-box__right2'>
                    <img src={chiro1} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Aboutus;
