import React from "react";
import Feature from "../../components/feature/Feature.js";
import "./Gonstead.css";

const Gonstead = () => (
    <div className='gonstead-section'>
        <div className='gpt3__whatgpt3 section__margin' id='wgpt3'>
            <div className='gpt3__whatgpt3-feature'>
                <Feature
                    title='What is Gonstead Technique?'
                    text='The Gonstead Concept of chiropractic begins with a basic biomechanical principle of physics. Every engineer, architect, builder and carpenter knows the importance of a proper foundation in constructing a building, for this insures durability and long life. Any slight change or shift in the foundation can cause a great amount of deviation in the top part of the structure and, perhaps, ultimately, its collapse.'
                />
            </div>
            <div className='gpt3__whatgpt3-heading'>
                <h1 className='gradient__text'>
                    The Basic Principles of the Gonstead Method of Chiropractic
                    Analysis
                </h1>
                <p>Explore the Gonstead</p>
            </div>
            <div className='gpt3__whatgpt3-container'>
                <Feature
                    title='Visualization'
                    text='Visualization is a way to cross reference all the other findings. Your chiropractor is an expert in looking for subtle changes in your posture and movement which could indicate any problems.'
                />
                <Feature
                    title='Instrumentation'
                    text='The instrument of choice in the Gonstead System is the Nervoscope. The Nervoscope detects uneven distributions of heat along the spine which can be indicative of inflammation and nerve pressure. This instrument is guided down the length of your back and feels like two fingers gliding down each side of your spine.'
                />
                <Feature
                    title='Motion Palpation'
                    text=' This process involves feeling the spine while moving and bending it at various angles. This enables the chiropractor to determine how easily or difficult each segment in your spine moves in different directions.'
                />
            </div>
        </div>
    </div>
);

export default Gonstead;
