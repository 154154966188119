import React, { useEffect, useState } from "react";
import "./Slider.css";
import dataSlider from "./dataSlider.js";
import BtnSlider from "./BtnSlider";
import NewHeader from "../../components/header/NewHeader";

function Slider() {
    const [slideIndex, setSlideIndex] = useState(1);
    const [letterPop, setLetterPop] = useState("slider-font");

    const nextSlide = () => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1);
        } else if (slideIndex === dataSlider.length) {
            setSlideIndex(1);
        }
    };
    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1);
        } else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setLetterPop("slider-font-active");
        }, 400);
    }, []);

    return (
        <div className='slider1-section'>
            {/* <NewHeader /> */}
            <div className='slider-container'>
                <div className={letterPop}>
                    <h1>SAFE, GENTLE, EFFECTIVE</h1>
                    <h2>Achieve quick and Lasting result</h2>
                    {/* <h3>
            The goal of chiropractic is to get every patients well as soon as
            possible and for them to stay well for as long as possible
          </h3> */}
                    <div className='slider-section-button'>
                        <button>Lrean more</button>
                    </div>
                </div>
                {dataSlider.map((obj, index) => {
                    return (
                        <div
                            key={obj.id}
                            className={
                                slideIndex === index + 1
                                    ? "active-anim"
                                    : "slide"
                            }
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    `/Imgs/img${index + 1}.jpg`
                                }
                                alt='#'
                            />
                        </div>
                    );
                })}

                <BtnSlider moveSlide={nextSlide} direction={"next"} />
                <BtnSlider moveSlide={prevSlide} direction={"prev"} />
            </div>
        </div>
    );
}

export default Slider;
