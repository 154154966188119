import React from "react";
import "./Info2.css";
import { Fieldset } from "primereact/fieldset";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import card1 from "../../photo/card-img1.jpeg";
import card2 from "../../photo/card-image2.jpeg";
import card3 from "../../photo/card-image3.jpeg";
import {
  FaMoneyBillAlt,
  FaChartLine,
  FaHandHoldingHeart,
  FaWpforms,
  FaThumbsUp,
} from "react-icons/fa";
import { MdAttachMoney } from "react-icons/md";

function Info2() {
  return (
    <section>
      <div className="info2-section">
        <div className="info2-container">
          <h1>Why Customers Choose Us?</h1>

          <div>
            <div className="about__major">
              <div className="major">
                <div className="major__icon">
                  <i>
                    <FaChartLine size={30} />
                  </i>
                </div>
                <h2 className="major__title">Experience</h2>
                <div className="major__description">
                  <p>
                    As we are working out of <br />
                    our home we have low over <br />
                    heads and can therefore keep fees to a minimum.
                  </p>
                </div>
              </div>
              <div className="major">
                <div className="major__icon">
                  <i>
                    <FaHandHoldingHeart size={30} />
                  </i>
                </div>
                <h2 className="major__title">
                  Caring Patient Centered Approach
                </h2>
                <div className="major__description">
                  <p>
                    We put your health first and will get <br />
                    you in the best possible condition
                  </p>
                </div>
              </div>
              <div className="major">
                <div className="major__icon">
                  <i>
                    <FaMoneyBillAlt size={30} />
                  </i>
                </div>
                <h2 className="major__title">No Upfront Payments</h2>
                <div className="major__description">
                  <p>
                    Every case is unique so we never utilize
                    <br />
                    pre-scheduling or upfront payment plans.
                  </p>
                </div>
              </div>
            </div>
            <div className="about__major">
              <div className="major">
                <div className="major__icon">
                  <i>
                    <FaWpforms size={30} />
                  </i>
                </div>
                <h2 className="major__title">Insurance Claims</h2>
                <div className="major__description">
                  <p>
                    We can scan your health insurance card <br />
                    through our eftpos terminal and you <br />
                    pay only the gap.
                  </p>
                </div>
              </div>
              <div className="major">
                <div className="major__icon">
                  <i>
                    <FaThumbsUp size={28} />
                  </i>
                </div>
                <h2 className="major__title">Effectiveness</h2>
                <div className="major__description">
                  <p>
                    On average Patients achieve their
                    <br />
                    desired results in 3 to <br />4 Clinic visits.
                  </p>
                </div>
              </div>
              <div className="major">
                <div className="major__icon">
                  <i>
                    <MdAttachMoney size={30} />
                  </i>
                </div>
                <h2 className="major__title">Price</h2>
                <div className="major__description">
                  <p>
                    As we are working out of our home <br />
                    we have low over heads and can <br />
                    therefore keep fees to a minimum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info2;
