import React from "react";
import "./Info3.css";

function Info3() {
  return (
    <section>
      <div className="info3-section">
        <div className="info3-container">
          <h1>Your Client Care Plan With The Focus way</h1>
          {/* <ul className="story-line">
            <div class="blobs-container">
              <div class="blob2 white2"></div>
              <div class="blob2 red2"></div>
              <div class="blob2 orange2"></div>
              <div class="blob2 yellow2"></div>
              <div class="blob2"></div>
            </div>
          </ul> */}
        </div>
      </div>
    </section>
  );
}

export default Info3;
