import React from "react";
import "./BigCard.css";
import aboutusimg from "../../photo/focus-shop3.jpeg";
import { BsFillCheckCircleFill } from "react-icons/bs";

function BigCard(imgname, text) {
    return (
        <div className='bigcard-box'>
            <div className='bigcard-box__left'>
                <ul>
                    <li>
                        Specific Gonstead Technique
                        <br /> Gonstead
                    </li>

                    <li>Improving mobility and range of motion</li>
                    <li>Spinal care for all age</li>
                    <li>Minimal waiting time (we respect your time!</li>

                    <li>Eftpos & Hi-Caps availble</li>
                </ul>
            </div>
            <div className='bigcard-box__right'>
                <img src={aboutusimg} alt='' className='bigcard-img' />
            </div>
        </div>
    );
}

export default BigCard;
