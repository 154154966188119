import React, { useState, useEffect } from "react";
import "./Carousel.css";
import { Example } from "../../components/index";
import people from "../../photo/people.png";
import body2 from "../../photo/noback.png";
import asso1 from "../../photo/asso1.png";
import asso2 from "../../photo/asso2.png";
import asso3 from "../../photo/asso3.png";

function Carousel() {
  return (
    <>
      <div className="carousel-section">
        <div className="carousel-contents">
          <h1>Brisbane No.1 Gonstead Chiropractic</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>

          <div className="carousel-contents__people">
            <img src={people} alt="" />
            <p>
              more then 1000 patients improved their body pain related issue
            </p>
          </div>
        </div>
        {/* <div className="carousel-contents__image">
          <img src={body2} alt="body2" width={600} />
        </div> */}
      </div>
    </>
  );
}

export default Carousel;
