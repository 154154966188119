import React from "react";
import "./CTA.css";
import { CalendarDots } from '@phosphor-icons/react'

const CTA = () => (
    <div className='gpt3__cta'>
        <div className='gpt3__cta-content'>
            <CalendarDots size={24} color={"#fff"} />
            <h3>
                Contact us today so we can help you live your life to the
                fullest
            </h3>
        </div>
        <div className='gpt3__cta-btn'>
            <a href='https://chiropractic-focus.au1.cliniko.com/bookings?embedded=true'>
                <button type='button'>Booking Now</button>
            </a>
        </div>
    </div>
);

export default CTA;
