import styled from "styled-components";
import React, { useState, useEffect } from "react";

const Container = styled.div`
    @import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap");
    height: 30px;
    width: 100%;
    background-color: #fff;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 0;
    cursor: pointer;
    z-index: 999;
`;
const CallUs = styled.a`
    color: black;
`;

function NewHeader() {
    const [callUs, setCallUs] = useState("Click to call (425) 844 043");

    function calling() {
        console.log("calling");
    }

    return (
        <Container
            onClick={() => {
                calling();
            }}
        >
            <CallUs href='tel:+61425844043'>{callUs}</CallUs>
        </Container>
    );
}

export default NewHeader;
