import React from "react";
import NewHeader from "../../components/header/NewHeader";
import { Navbar } from "../../components";
import { Footer } from "../../containers";
import styled from "styled-components";
import drgonsted from "../../photo/drgonstead.jpeg";
import gonsteads from "../../photo/gonstead-logo2.png";
import { GrSchedules } from "react-icons/gr";

const Container = styled.div`
    width: 100vw;
    height: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
`;
const SmallContainer = styled.div`
    height: 100%;
    display: flex;
    padding: 60px;
    padding-bottom: 0px;
    @media screen and (max-width: 550px) {
        padding: 10px;
        flex-direction: column;
    }
`;

const Label = styled.h1`
    position: absolute;
    left: 50%;
    margin-left: -50px;
    background-color: black;
    color: #fff;
    z-index: 1;
    @media screen and (max-width: 550px) {
        left: 50%;
        margin-left: -70px;
    }
`;
const ContentLeft = styled.div`
    flex-basis: 50%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 550px) {
        flex-basis: 50%;
        position: relative;
        display: flex;
        justify-content: center;
    }
`;

const DrGonstead = styled.img`
    width: 80%;
    height: 90%;
    border-radius: 2%;
`;
const ContentRight = styled.div`
    margin-top: 40px;
    padding: 20px;
    flex-basis: 50%;
`;
const RightContexth1 = styled.h3`
    margin: 20px 0;
`;
const RightContextp = styled.p`
    margin: 20px 0;
`;

const Container2 = styled.div`
    height: 100%;
    display: flex;
    @media screen and (max-width: 550px) {
        flex-direction: column;
    }
`;
const ContentLeft2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 60px;
    flex-basis: 50%;
    @media screen and (max-width: 550px) {
        justify-content: center;
        padding: 30px;
        padding-bottom: 0;
    }
`;
const ContentRight2 = styled.div`
    flex-basis: 50%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 550px) {
        justify-content: center;
        margin-bottom: 20px;
    }
`;
const GonsteadAsso = styled.img`
    width: 80%;
    height: 90%;
    border-radius: 2%;
`;

const MiddleLetter = styled.div`
    padding: 10px 100px;
`;

const Container3 = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 40px;
    @media screen and (max-width: 550px) {
        flex-direction: column;
    }
`;

const Container3Content = styled.div`
    padding: 20px;
`;

const ContactUs = styled.div`
    width: 80%;
    margin: auto;
    max-height: 10vh;
    background-color: #116989;
    color: #fff;
    padding: 10px;
    @media screen and (max-width: 550px) {
        max-height: 30vh;
    }
`;

function Gonstead() {
    return (
        <div id='gonstead'>
            <NewHeader />
            <Navbar />
            <Container>
                <SmallContainer>
                    <Label>GONSTEAD</Label>
                    <ContentLeft>
                        <DrGonstead src={drgonsted} />
                    </ContentLeft>
                    <ContentRight>
                        <RightContexth1>
                            Gonstead Technique in Chiropractic Treatment
                        </RightContexth1>
                        <RightContextp>
                            The developer of Gonstead Technique was Dr. Clarence
                            Selmer Gonstead. Dr. Gonstead came across
                            chiropractic care when cases of extremely severe leg
                            and foot pain started responding to chiropractic
                            adjustments after failing to respond to conventional
                            medical care. The Gonstead theory of chiropractic
                            starts with the intervertebral discs and the body’s
                            structural base. If the pelvis, vertebrae and legs
                            are at level, then maximum stability and balance in
                            spinal column can be achieved. The Gonstead
                            chiropractic technique chiefly focuses on uneven
                            foundation, motion disturbances, intervertebral
                            misalignments as well as dysfunction of nerves. Any
                            damage to the intervertebral disc is the chief
                            reason for causing subluxation of spine; hence
                            Gonstead adjustments are designed in such a way that
                            the discs are primarily benefited. This technique
                            also helps in restoring normal motion and alignment.
                        </RightContextp>
                        <RightContextp>
                            The basic assessment tool in the Gonstead technique
                            is a complete spine radiograph. Other important
                            examination procedures in this technique are static
                            and dynamic palpations, mainly of paraspinal bone
                            structures and soft tissues, visualizing movement of
                            spine, posture, gait and looking for inflammatory
                            signs near the joints, instrumentation such as
                            assessment of paraspinal skin temperature and other
                            devices. All these are used to look at various
                            aspects of the patient’s neuromuscularskeletal
                            characteristics. The chiropractor assesses the
                            complete spine radiograph and draws measurements on
                            film to analyze relative positions of adjacent
                            vertebra and pelvis regions. The chiropractor
                            converts these measurements into listings along with
                            other variables about the aspects of subluxation in
                            order to regulate the precise application of the
                            adjustment forces.
                        </RightContextp>
                        <RightContextp>
                            Gonstead adjustments are high velocity, short lever
                            and low amplitude and have a long lever, which aids
                            in application of the force. The high velocity
                            comprises of the fast application of force. The
                            exact contact on the vertebra near or on the
                            vertebral midline is the short lever. The low
                            amplitude is the depth of the force, which is being
                            controlled at a superficial level, just sufficient
                            enough to affect the disc and joint. The long lever
                            helps in stabilizing the area above and/or below the
                            contact area, which is being adjusted and commonly
                            involves the use of the chiropractor’s hand or thigh
                            for holding it.
                        </RightContextp>
                        <RightContextp>
                            There are various types of tables used for adequate
                            positioning of patient during the Gonstead
                            adjustments, one of which is the Hi-Lo table, which
                            has special modifications and was made specifically
                            for Dr. Gonstead long time ago. It has been
                            essential equipment in this technique and aids a
                            wide range of patients to include pregnant females.
                            Another table used is known as knee-chest table.
                            This table was one in a set of Gonstead’s uniquely
                            emphasized tables. It helps in facilitating
                            posterior-to-anterior correction of subluxations and
                            also repositions the posteriorly displaced
                            intervertebral discs. The complete spine benefits
                            from this table. The knee-chest table is also an
                            excellent option for pregnant females.
                        </RightContextp>
                    </ContentRight>
                </SmallContainer>
                <Container2>
                    <ContentLeft2>
                        <RightContexth1>
                            Three questions to ask a chiropractor about the
                            Gonstead method
                        </RightContexth1>
                    </ContentLeft2>
                    <ContentRight2>
                        <GonsteadAsso src={gonsteads} />
                    </ContentRight2>
                </Container2>
                <MiddleLetter>
                    The Gonstead method is proven to be one of the best ways to
                    administer chiropractic care. Talk with your chiropractor
                    about this form of treatment so that they can get you
                    started, but for now, read on to find out some popular
                    questions and answers.
                </MiddleLetter>

                <Container3>
                    <Container3Content>
                        <h3>1. How does the Gonstead method work?</h3>
                        <p>
                            Chiropractic treatment comes in a few different
                            forms and will be appropriately administered based
                            on the patient’s specific needs. This method works
                            using a hands-on approach in which the chiropractor
                            adjusts and works the back while the patient is on
                            their side. Mobility can be increased, which can
                            help patients achieve more natural movement. The
                            joints in the spinal region can also be realigned
                            using the Gonstead method, which can help to reduce
                            joint pain or stiffness.
                        </p>
                    </Container3Content>
                    <Container3Content>
                        <h3>2. Is the Gonstead method a common practice?</h3>
                        <p>
                            Almost all chiropractors are trained to practice the
                            Gonstead method. It is universally known and
                            practiced because of the results that it has.
                            Patients often benefit from the treatment, which
                            makes it more of a necessity for chiropractors to
                            learn in medical school. Chiropractors find that the
                            Gonstead method is also easily customizable, which
                            is again a reason for its commonality. Each patient
                            has different needs, which makes it important for
                            treatment to be specifically for them. A careful
                            evaluation of the Gonstead method will ensure that
                            the treatment goes accordingly.
                        </p>
                    </Container3Content>
                    <Container3Content>
                        <h3>3. Is the Gonstead method for everyone?</h3>
                        <p>
                            Not necessarily. While the Gonstead method is proven
                            to work best for spinal issues, there are some
                            people who need chiropractic treatment due to other
                            back or neck problems, which may be unrelated to the
                            spine. In these scenarios, it is best to consult
                            with the chiropractor to determine what is best.
                            However, if the pain or stiffness is due to the
                            spine then the chiropractor will likely recommend
                            the Gonstead method because it has proven good
                            results. If this method of treatment doesn&#39;t
                            work then another type of chiropractic care will be
                            recommended.
                        </p>
                    </Container3Content>
                </Container3>
                <ContactUs>
                    <h3>
                        Are you interested in learning more about the Gonstead
                        method? Our team of chiropractors can help you. We are
                        readily available to answer your questions or get you
                        started with the chiropractic care that you deserve.
                        Give us a call or stop by today!
                    </h3>
                </ContactUs>
            </Container>

            <div className='gpt3__cta'>
                <div className='gpt3__cta-content'>
                    <GrSchedules size={20} color={"#fff"} />

                    <h3>
                        Contact us today so we can help you live your life to
                        the fullest
                    </h3>
                </div>
                <div className='gpt3__cta-btn'>
                    <a href='https://chiropractic-focus.au1.cliniko.com/bookings?embedded=true'>
                        <button type='button'>Booking Now</button>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Gonstead;
