import React, { useState, useEffect } from "react";
import "./Landing.css";
import NewHeader from "../../components/header/NewHeader";
import styled, { keyframes } from "styled-components";
import { Navbar } from "../../components";

const Slide = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    /* flex-direction:column; */
    display: inline-block;
    background: url(${(props) => props.image}) center/cover no-repeat;
    /* background-color: ${(props) => props.bg}; */
    @media screen and (max-width: 720px) {
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }
`;

const InfoContainer = styled.div`
    flex: 1;
    padding: 50px;
    display: flex;
`;

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const Desc = styled.h1`
    @import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");
    animation: fadeIn 3000ms;
    animation-name: ${fadeIn};
    margin-top: 40px;
    font-size: 42px;
    letter-spacing: 3px;
    opacity: 1;
    transition: opacity 1s ease;
    @media screen and (max-width: 720px) {
        background: #fff;
        padding:4px;
        color: black;
        font-size: 22px;
    }
`;

const BoxLeft = styled.div`
    height: 100%;
    flex-basis: 50%;
    /* background-color: coral; */
    @media screen and (max-width: 720px) {
        display: none;
    }
`;
const BoxRight = styled.div`
    height: 100%;
    flex-basis: 50%;
`;

function Landing() {
    const bgs = [
        {
            img: require("../../photo/sliderimg/slideback.png"),
            desc: "Gonstead Chiropractic",
        },
        {
            img: require("../../photo/sliderimg/landing1.jpeg"),
            desc: "Quick and Lasting result",
        },
        {
            img: require("../../photo/sliderimg/landing3.jpeg"),
            desc: "SAFE, GENTLE, EFFECTIVE",
        },
    ];

    const delay = 2000;
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === bgs.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );
        return () => {};
    }, [index]);

    return (
        <div className='landing-section'>
            <NewHeader />
            <Navbar />
            <div
                className='slideshowSlider'
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {bgs.map(({ img, desc }, index) => (
                    <Slide image={img} key={index}>
                        <InfoContainer>
                            <BoxLeft></BoxLeft>
                            <BoxRight>
                                <Desc>{desc}</Desc>
                            </BoxRight>
                        </InfoContainer>
                    </Slide>
                ))}
            </div>
            <div className='slideshowDots'>
                {bgs.map((_, idx) => (
                    <div
                        key={idx}
                        onClick={() => {
                            setIndex(idx);
                        }}
                        className={`slideshowDot${
                            index === idx ? " active" : ""
                        }`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default Landing;
