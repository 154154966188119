import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { FiMenu } from "react-icons/fi";
import logo from "../../photo/logo2.png";
import { Link, NavLink } from "react-router-dom";

function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [headerClasses, setHeaderClasses] = useState(
        "landing-nav__right deactive"
    );
    const [imgSize, setImageSize] = useState("logo-box");

    useEffect(() => {
        const changeWidth = () => {
        setScreenWidth(window.innerWidth);
    };

        window.addEventListener("resize", changeWidth);
    }, []);

    const toggleNav = () => {
        setToggleMenu(!toggleMenu);
    };

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    return (
        <div className='landing-nav'>
            <div className='landing-nav__left'>
                <img src={logo} alt='' className='logo-img' />
            </div>
            <div className='landing-nav__right'>
                <button className='navbar__toggle__btn' onClick={toggleNav}>
                    <FiMenu size={45} />
                </button>
                <ul className={toggleMenu ? "nav-menu expanded" : "nav-menu"}>
                    <li className='navbar__menu__item'>
                        <NavLink to='/' onClick={handleClick}>
                            Home
                        </NavLink>
                    </li>
                    <li className='navbar__menu__item'>
                        <Link to='/aboutus' onClick={handleClick}>
                            About Us
                        </Link>
                    </li>
                    <li className='navbar__menu__item'>
                        <Link to='/gonstead' onClick={handleClick}>
                            Gonstead
                        </Link>
                    </li>
                    <li className='navbar__menu__item'>
                        <Link to='/firstvisit' onClick={handleClick}>
                            First Visit
                        </Link>
                    </li>
                    <li className='navbar__menu__item'>
                        <Link to='/service' onClick={handleClick}>
                            Services
                        </Link>
                    </li>
                    <li className='navbar__menu__item'>
                        <Link to='/contacts' onClick={handleClick}>
                            Contacts
                        </Link>
                    </li>
                    <li className='navbar__menu__item'>
                        <button 
                            className='navbar__menu__item-btn'
                        >
                            <a href='https://chiropractic-focus.au1.cliniko.com/bookings?embedded=true' style={{ color: 'white', textDecoration: 'none' }}>
                                Book Now
                            </a>
                        </button>
                    </li>
                    <div className='button-demo'></div>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
