import React from "react";
import "./Story.css";

function Story() {
  return (
    <section>
      <div className="story-section">
        <div className="story-container">
          <div className="story-line">
            <div class="blobs-container">
              <div class="blob white"></div>
              <div class="blob red"></div>
              <div class="blob orange"></div>
              <div class="blob yellow"></div>
              <div class="blob"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
