import React from "react";
import "./Contacts.css";
import { VscLocation } from "react-icons/vsc";
import { FiPhone } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import photoes1 from "../../photo/focus-shop1.jpeg";
import photoes2 from "../../photo/focus-shop3.jpeg";
import { Brands } from "../../containers/index";
import { Footer } from "../../containers/index";
import { Navbar } from "../../components/index";
import NewHeader from "../../components/header/NewHeader.js";

function Contacts() {
    return (
        <div className='contacts'>
            <NewHeader />
            <Navbar />
            <div className='tile'>
                <h1>Contacts</h1>
                <div className='liner'></div>
            </div>
            <div className='maps'>
                <div className='contacts-box'>
                    <h1>CONTACTS</h1>
                    <div className='liner'></div>
                    <div className='contact-box__items'>
                        <VscLocation color='#F4A622' />
                        <a href=''>
                            Unit5/1953 Logan Rd, Upper Mount Gravatt QLD 4122
                        </a>
                    </div>
                    <div className='contact-box__items'>
                        <AiOutlineMail color='#F4A622' />
                        <a href=''>drchae81@gmail.com</a>
                    </div>
                    <div className='contact-box__items'>
                        <div className='hours'>
                            <BiTime color='#F4A622' />
                            <p>Opening Hours</p>
                        </div>
                        <p href=''>Monday 2–7pm</p>
                        <p href=''>Tuesday 8am–7pm</p>
                        <p href=''>Wednesday 8am–1pm</p>
                        <p href=''>Thursday 8am–7pm</p>
                        <p href=''>Friday 8am–6pm</p>
                        <p href=''>Saturday 8am–2pm</p>
                        <p href=''>Sunday Closed</p>
                    </div>
                    <div className='contact-box__items'>
                        <FiPhone color='#F4A622' />
                        <a href=''> 0425 844 043</a>
                    </div>
                    <div className='contact-box__buttonbox'>
                        <a href='https://goo.gl/maps/ETXi5hEF24Evz4rW9'>
                            <button className='contact-box__button'>
                                Get Direction
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <Brands bgcolor={"brand-section2"} />
            <div className='photoes'>
                <div className='photoes__left'>
                    <img src={photoes1} alt='' />
                </div>
                <div className='photoes__right'>
                    <img src={photoes2} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contacts;
