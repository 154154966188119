import React from "react";
import "./Footer.css";
import logo from "../../photo/logo2.png"; // Tell webpack this JS file uses this image
import { ImFacebook2 } from "react-icons/im";
import { FaInstagramSquare } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";

function Footer() {
    return (
        <section className='footer-section'>
            <footer class='footer-distributed'>
                <div className='footer-left'>
                    <img src={logo} alt='' style={{objectFit: 'contain'}} />

                    <p className='footer-links'>
                        <a href='#' className='link-1'>
                            Home
                        </a>

                        <a href='#'>AboutUs</a>

                        <a href='#'>First Visit</a>

                        <a href='#'>Service</a>

                        <a href='#'>Contact</a>
                    </p>

                    <p className='footer-company-name'>
                        Chiropractic Focus © since 20xx
                    </p>
                </div>

                <div className='footer-center'>
                    <div>
                        <i className='fa fa-map-marker'></i>
                        <p>
                            <span>Unit5/1953</span> Logan Rd Upper Mount
                            Gravatt, QLD
                        </p>
                    </div>

                    <div>
                        <i className='fa fa-phone'></i>
                        <p>+61425844043</p>
                    </div>

                    <div>
                        <i className='fa fa-envelope'></i>
                        <p>
                            <a href='drchae81@gmail.com'>drchae81@gmail.com</a>
                        </p>
                    </div>
                </div>

                <div className='footer-right'>
                    <p className='footer-company-about'>
                        <span>Brisbane No.1 Gonstead Chiropractic</span>
                        Chiropractic Focus is professional Gonstead Technique
                        clinic located in Brisbane
                    </p>

                    <div className='footer-icons'>
                        <a href='#'>
                            <ImFacebook2 />
                        </a>
                        <a href='#'>
                            <FaInstagramSquare />
                        </a>
                        <a href='#'>
                            <AiFillYoutube />
                        </a>
                    </div>
                </div>
            </footer>
        </section>
    );
}

export default Footer;
