import react, { useEffect, useState } from "react";
import { ScrollToTop } from "../../components";
import {
    Landing,
    Footer,
    Info,
    Info2,
    Info3,
    Info4,
    Brands,
    Gonstead,
    CTA,
    Blog,
} from "../../containers";

function Home() {
    return (
        <>
            <div className='main'>
                <ScrollToTop />
                <Landing />
                <CTA />
                <Brands bgcolor='brand-section' />
                <Info />
                <Info2 />
                <Info3 />
                <Gonstead />
                <Info4 />
                <Footer />
            </div>
        </>
    );
}

export default Home;
